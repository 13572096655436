<template>
  <RadioTabs
    class="FieldSkipReservation"
    :disabled="disabled"
    label="Mokėjimai"
    :value="value"
    @update:value="$up('value', $event)">
    <div
      slot="false"
      data-cy="schedule-RESERVATION"
      :label="`Rezervacinis (100 €) + Avansas (${advanceAmountDefault}%)`"
      :value="false">
      Renkuosi mokėti 100 € rezervacinį mokestį dabar ir avansą per 10 dienų.
    </div>
    <div
      slot="true"
      data-cy="schedule-ADVANCE"
      :label="`Avansas (${advanceAmountDefault}%)`"
      :value="true">
      Renkuosi iškart mokėti avansą per 10 dienų.
    </div>
  </RadioTabs>
</template>

<script>
import RadioTabs from 'components/fields/RadioTabs'

export default {
  components: { RadioTabs },
  props: {
    value: Boolean,
    disabled: Boolean,
    advanceAmountDefault: Number,
  },
}
</script>

<style lang="scss">
.FieldSkipReservation {
  margin-bottom: $grid-unit;
}
</style>
