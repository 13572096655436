<script>
// import FieldManualCaptcha from '../components/FieldManualCaptcha'
import NoticeRecaptcha from '../components/NoticeRecaptcha'

export default {
  components: { NoticeRecaptcha },
  // data() {
  //   return {
  //     manualCaptcha: null,
  //   }
  // },
  methods: {
    async injectRecaptcha(entry, actionName) {
      // if (this.manualCaptcha) {
      //   return {
      //     ...entry,
      //     recaptcha: this.manualCaptcha,
      //   }
      // }

      const recaptcha = await (async () => {
        try {
          return await this.$recaptchaToken(actionName)
        } catch (error) {
          // this.manualCaptcha = ''
          throw new Error('Google sistemai nepavyksta patikrinti ar esate ne robotas. Prašome perkrauti puslapį iš naujo ir išjungti naršyklės įskiepius (extensions). Jeigu tai nepadeda, prašome pabandyti užsiregistruoti per kitą naršyklę arba kitu įrenginiu. Jeigu neturite priėjimo prie kito įrenginio, prašome susisiekti su mūsų komanda telefonu arba Facebook.')
        }
      })()

      return {
        ...entry,
        recaptcha,
      }
    },
  },
}
</script>

<style lang="scss">
</style>
