<template>
  <Field
    name="newsletter"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      Sutinku, kad nurodyti asmens duomenys būtų naudojami UAB „Saulės bendruomenė LT“ tiesioginės rinkodaros tikslais, t. y. naujienlaiškių ir kitų pasiūlymų siuntimui.
    </span>
  </Field>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>
