<template>
  <Field
    name="policy"
    :rules="{ required: { allowFalse: false } }"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      Su <Route to="PrivacyPolicy">privatumo politika</Route> susipažinau ir sutinku, kad mano asmens duomenys būtų tvarkomi užklausos vykdymo tikslais
    </span>
  </Field>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>
