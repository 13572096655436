<template>
  <div class="RadioTabs" :class="{ disabled }">
    <FieldLabel v-if="label" :label="label" :required="required" />
    <div class="tabs">
      <div
        v-for="name in slots"
        :key="name"
        class="option"
        :class="{
          active: $slots[name][0].data.attrs.value === value,
          disabled: $slots[name][0].data.attrs.disabled,
        }"
        :data-cy="`value:${$slots[name][0].data.attrs.value}`"
        @click="setSlotValue(name)"
        @keypress.enter="setSlotValue(name)">
        <div class="body">
          <div class="label">
            {{ $slots[name][0].data.attrs.label || name }}
          </div>
          <div class="content">
            <slot :name="name" />
          </div>
        </div>
      </div>
    </div>
    <Field
      v-if="required && value === null"
      required
      type="hidden" />
  </div>
</template>

<script>
import Tabable from 'mixins/Tabable'

export default {
  mixins: [Tabable],
  props: {
    disabled: Boolean,
    label: String,
    required: Boolean,
    value: null,
  },
  methods: {
    setSlotValue(slotName) {
      const slotAttributes = this.$slots[slotName][0].data.attrs

      if (this.disabled || slotAttributes.disabled) return

      this.$emit('update:value', slotAttributes.value)
    },
  },
}
</script>

<style lang="scss">
$_radio-size: 1.5rem;

.RadioTabs {
  > .tabs {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }

  .option {
    border: 1px solid $border-color;
    border-radius: $radius-lg;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    margin-bottom: $grid-unit;
    padding: $grid-unit;
    @include md {
      margin-bottom: 0;
      margin-right: $grid-unit;
    }

    &::before {
      align-self: center;
      background: $white;
      border: 0.4rem solid $white;
      border-radius: 50%;
      box-shadow: 0 0 0 1px $border-color;
      content: '';
      display: block;
      height: $_radio-size;
      min-width: $_radio-size;
      width: $_radio-size;
    }

    > .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      padding-left: 1rem;

      > .label {
        font-weight: $semibold;
      }

      > .content {
        font-size: $h5;
        font-weight: $regular;
      }

      .Field-cont {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      background: $info-light;
      border-color: $info-dark;
      color: $info-dark;

      &::before {
        background: $info-dark;
        box-shadow: 0 0 0 1px $info-dark;
      }
    }

    &.disabled {
      border-color: $border-color-light;
      color: $text-color-light;
      cursor: default;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.disabled {
    .option {
      cursor: default;

      &:not(.active) {
        color: $text-color-mid;
      }
    }
  }
}
</style>
