<template>
  <div class="FormFloat">
    <FormValidation @submit="$emit('submit')">
      <div class="actions">
        <h1 v-if="doShowTitle">
          {{ title }}
        </h1>
      </div>
      <Wrap>
        <div class="inner">
          <slot />
        </div>
      </Wrap>
    </FormValidation>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    doShowTitle: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.FormFloat {
  h1 {
    @include dotAbove(1.5rem);
    color: $text-color-mid;
    font-size: $h2;
    line-height: $lh * 2;
    margin-top: 4rem;
    text-align: center;
  }

  .actions {
    font-size: $h3;
    line-height: $lh;
    padding: 1.5rem 0;
    position: relative;
    @include md {
      padding: 1.5rem;
    }
  }

  .Wrap {
    padding: 1rem 3rem;
  }

  .inner {
    padding-top: 1rem;

    .Btn {
      margin-bottom: 1rem;
    }
  }

  .foot {
    padding: 1rem 0;
    text-align: center;

    a {
      border-bottom: none;

      .Btn.link {
        border: none;
        display: inline-block;

        > span {
          border-bottom: 1px solid $main;
        }
      }
    }
  }

  .FormSect {
    padding: 1rem;
  }

  // margin fix if in a column
  .Column > button {
    margin: 0;
  }

  &.small-pad {
    .inner {
      padding: 0 1rem;
    }
  }
}
</style>
