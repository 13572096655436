<script>
export default {
  props: {
    initial: String,
  },
  data() {
    return {
      active: null,
    }
  },
  computed: {
    slotsIds() {
      return Object
        .entries(this.$slots)
        .map(([key, [element]]) => [key, (element.data.attrs || {}).id])
        .filter(([_, id]) => id)
    },
    slots() {
      return Object.keys(this.$slots)
    },
  },
  watch: {
    active(activeTabName) {
      this.$emit('change', activeTabName)
    },
  },
  beforeMount() {
    const [slotKey] = this.slotsIds
      .find(([_, id]) => this.$route.hash === `#${id}`) || []

    this.active = slotKey || this.initial || this.slots[0]
  },
}
</script>
