<template>
  <div class="NoticeRecaptcha">
    Šis puslapis naudoja reCAPTCHA ir dėl to galioja Google <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">
      privatumo politika
    </a> ir <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">
      paslaugų teikimo sąlygos
    </a>.
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.NoticeRecaptcha {
  font-size: $h6;
  margin: 1rem 0;
}
</style>
