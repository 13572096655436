<script>
const isNotNil = value => value !== null && value !== undefined

export default {
  data() {
    return {
      message: null,
    }
  },
  methods: {
    setErrorMessage(error) {
      if (error.errors && error.errors.length && error.errors.some(isNotNil)) {
        const errorNonNil = error.errors.find(isNotNil)
        this.danger(errorNonNil)
      } else if (typeof error === 'string') {
        this.danger(error)
      } else {
        this.danger(error.message)
      }
    },
    setMessage({ theme = 'neutral', text = '' } = {}) {
      this.message = { theme, text }
    },
    clearMessage() {
      this.message = null
    },
    success(text) {
      this.setMessage({ theme: 'success', text })
    },
    warning(text) {
      this.setMessage({ theme: 'warning', text })
    },
    danger(text) {
      this.setMessage({ theme: 'danger', text })
    },
  },
}
</script>
